import React, { useCallback, useEffect, useMemo, useState} from 'react';
import Loadable from "react-loadable";
import WelcomePage from "./components/pages/WelcomePage";
import {Router, useNavigate} from "@reach/router";
import HomePage from "./components/pages/HomePage";
import {parseQuery} from "./util/RestUtil";
import {
    defaultRoundup,
    roundupReducer, SET_HIDDEN,
    SET_METADATA,
    SET_ROUNDUP_POSTS, SET_SINGLE_METADATA, SET_VIEWED,
    SubredditConfigContext
} from "./util/context/SubredditConfigContext";
import {convertBackendToFrontendJson} from "./util/ConvertJson";
import Cookies from 'universal-cookie';
import {
    cloneDeep,
    DELIVER_BY_EMAIL,
    DELIVER_BY_REDDIT,
    ERROR_TOAST,
    INFO_TOAST, IS_DEV,
    logger,
    SUCCESS_TOAST,
    WARNING_TOAST
} from "./util/Util";
import {ADD_SUBREDDITS, subredditListReducer, VerifiedAccountsContext} from "./util/context/VerifiedAccountsContext";
import {toast, ToastContainer} from "react-toastify";
import {MyToastContext} from "./util/context/MyToastContext";
import {HttpContext, useHttp} from "./util/hooks/useHttp";
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from "./util/ErrorBoundary";
import MyNavBar from "./components/MyNavBar";
import {useReducerWithSessionStorage, useStateWithSessionStorage} from "./util/hooks/useSessionStorage";
import WebsocketListener from "./util/websocket/WebsocketListener";
import useResetScroll from "./util/hooks/useResetScroll";
import Loading from "./components/Loading";
import Button from "react-bootstrap/Button";
import {ViewRoundup} from "./components/roundup/ViewRoundup";
import {MediaQueueWrapper} from "./components/media_queue/state/MediaQueueWrapper";
import {mediaQueueReducer} from "./components/media_queue/state/mediaQueueReducer";


export function shouldRedirectToWelcome() {
    let ssnb = shouldShowNavBar()
    let path = !window.location.pathname.startsWith("/roundups/")
    logger("ssnb: ", ssnb, " path: ", window.location.pathname, path)
    return ssnb && path;
}
export function shouldShowNavBar() {
    return !window.location.pathname.startsWith("/welcome")
        && !window.location.pathname.startsWith("/passwordReset")
        && !window.location.pathname.startsWith("/forgotPassword")
        && !window.location.pathname.startsWith("/login")
        && !window.location.pathname.startsWith("/signup");
}

export function hasRedditError(isLoaded, foundRedditConfig, redditAccount) {
    return isLoaded && ((foundRedditConfig && !redditAccount) || redditAccount?.messageError)
}

export const ANON_USER = "anon"
export const NULL_USER = "null"

require('dotenv').config()

// noinspection DuplicatedCode
function App(props) {
    let cookies = useMemo(() => {
        return new Cookies();
    }, [])

    let [xsrfToken, setXsrfToken, sendRequest] = useHttp(true);
    const nav = useNavigate()
    useResetScroll()

    let [didFetchConfigData, setFetchedConfigData] = useState(false);

    let [displayName, setDisplayName] = useState(NULL_USER);
    let [userId, setUserId] = useState(NULL_USER);
    let [deliveryMethod, setDeliveryMethod] = useState("");
    let [entityProvider, setEntityProvider] = useState("");
    let [subConfigs, setSubConfigs] = useStateWithSessionStorage(userId + "_subconfigs", []);
    let [redditAccount, setRedditAccount] = useStateWithSessionStorage(userId + "_reddit-acct", null);
    let [emailAccount, setEmailAccount] = useStateWithSessionStorage(userId + "_email-addr", null);
    let [didShowToast, setDidShowToast] = useState(false);
    let [spotifyJsLoaded, setSpotifyJsLoaded] = useState(false);

    let [spotifyAuth, setSpotifyAuth] =
        useStateWithSessionStorage("rr-spotify-auth", {
            spotifyAccess: null,
            spotifyRefresh: null,
            spotifyExpires: null,
            spotifyDeviceId: null,
            spotifyUsername: null
        });

    let websocket = WebsocketListener()
    let [registeredWebsocket, setRegisteredWebsocket] = useState(false);

    let [isConfigDataLoading, setIsConfigDataLoading] = useState(false);
    let [isConfigDataLoaded, setIsConfigDataLoaded] = useState(false);

    let [isRoundupDataLoading, setIsRoundupDataLoading] = useState(false);
    let [isRoundupDataLoaded, setIsRoundupDataLoaded] = useState(false);

    let [isUserDataLoading, setIsUserDataLoading] = useState(false);
    let [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
    let [subredditList, subredditListDispatch] =
        useReducerWithSessionStorage(userId + "_subredditList", subredditListReducer, []);

    let [roundupData, roundupDataDispatch] =
        useReducerWithSessionStorage(userId + "_roundup", roundupReducer, defaultRoundup);


    let foundEmailConfig = subConfigs.find((conf) => conf.deliveryMethod === DELIVER_BY_EMAIL);
    let emailError = isUserDataLoaded && ((emailAccount && emailAccount.isVerified === "false") || (foundEmailConfig && !emailAccount));
    let foundRedditConfig = subConfigs.find((conf) => conf.deliveryMethod === DELIVER_BY_REDDIT);
    let redditError = hasRedditError(isUserDataLoaded && isConfigDataLoaded, foundRedditConfig, redditAccount);

    // useImperativeHandle(ref, () => ({
    //     spotifyJsHasLoaded() {
    //         setSpotifyJsLoaded(true)
    //     }
    // }));

    useEffect(() => {
        if (IS_DEV) {
            document.title = window.location.pathname
        }
    }, [])

    useEffect(() => {
        if (props.spotifyLoaded && !spotifyJsLoaded) {
            console.log("spotifyloaded from props")
            setSpotifyJsLoaded(true)
        }
    }, [spotifyJsLoaded, props])

    window.onSpotifyWebPlaybackSDKReady = function () {
        console.log("spotifyloaded in app")
        setSpotifyJsLoaded(true);
    }

    const addUnicodeToToast = useCallback((message, unicode) => {
        if (typeof message === "string") {
            message = message.trim()
            return unicode + " " + message
        }
        return message;
    }, [])

    const addToast = useCallback((message, toastType, length=null) => {
        let toastOptions = {};
        if (length) {
            toastOptions.autoClose = length
        }
        switch (toastType) {
            case INFO_TOAST:
                message = addUnicodeToToast(message, "ℹ️")
                toast.info(message, toastOptions);
                break;
            case SUCCESS_TOAST:
                message = addUnicodeToToast(message, "✅")
                toast.success(message, toastOptions);
                break;
            case WARNING_TOAST:
                message = addUnicodeToToast(message, "⚠️")
                toast.warning(message, toastOptions);
                break;
            case ERROR_TOAST:
                // toastOptions.autoClose = autoClose || false
                // message = addUnicodeToToast(message, "✖")
                toast.error(message, toastOptions);
                break;
            default:
                toast(message, toastOptions);
        }
    }, [addUnicodeToToast])


    // lazy loaded components:
    const LazyPasswordReset = Loadable({
        loader: () => import("./components/auth/PasswordReset"),
        loading: Loading
    })
    const LazyForgotPassword = Loadable({
        loader: () => import("./components/auth/ForgotPassword"),
        loading: Loading
    })
    const LazyEditConfig = Loadable({
        loader: () => import("./components/config/EditConfig"),
        loading: Loading
    })
    const LazyAboutPage = Loadable({
        loader: () => import("./components/pages/AboutPage"),
        loading: Loading
    })
    const LazyPrivacyPolicyPage = Loadable({
        loader: () => import("./components/pages/PrivacyPolicyPage"),
        loading: Loading
    })
    const LazyProfilePage = Loadable({
        loader: () => import("./components/pages/ProfilePage"),
        loading: Loading
    })

    function deleteConfig(configId) {
        let configsCopy = cloneDeep(subConfigs);
        let remainingConfigs = configsCopy.filter((conf) => {
            return conf.id+"" !== configId+"";
        })
        setSubConfigs(remainingConfigs);
    }

    const insertConfigIntoExistingConfigs = useCallback((newConfig) => {
        let configsCopy = cloneDeep(subConfigs);
        // check if a config for the given subreddit already exists
        let existingConfig = configsCopy.find(conf => conf.subreddit === newConfig.subreddit);
        if (existingConfig) {
            // if it does exist, replace it with the new config
            configsCopy = configsCopy.map((conf) => {
                if (conf.subreddit === newConfig.subreddit) {
                    return newConfig;
                } else {
                    return conf;
                }
            });
        } else {
            configsCopy.push(newConfig);
            configsCopy.sort((a, b) => (a.subreddit.toLowerCase() > b.subreddit.toLowerCase()) ? 1 : -1);
        }

        setSubConfigs(configsCopy);
    }, [subConfigs, setSubConfigs])

    function setConfig(jsonConfig) {
        // if we don't know the id of this config, reload all config data
        // (otherwise the user won't be able to delete this config)
        if (!jsonConfig.id) {
            return loadSingleConfig(jsonConfig.subreddit);
        } else {
            insertConfigIntoExistingConfigs(jsonConfig);
        }

        return Promise.resolve(jsonConfig);
    }

    const shouldLoadData = useCallback((force) => {
        return (!didFetchConfigData && shouldShowNavBar()) || force;
    }, [didFetchConfigData])

    const addSubreddits = useCallback((newList) => {
        subredditListDispatch({type: ADD_SUBREDDITS, payload: newList})
    }, [subredditListDispatch])

    const loadUserData = useCallback((force) => {
        if (shouldLoadData(force)) {
            let opts = {method: "GET"}
            setIsUserDataLoading(true)
            sendRequest(`/api/userdata/`, opts)
                .then((resp) => {
                    // log("/userdata resp: ", resp)
                    let display = resp.displayName === "null" ? ANON_USER : resp.displayName
                    let userId = resp.userId === "null" ? ANON_USER : resp.userId
                    setDisplayName(display)
                    setUserId(userId)
                    setDeliveryMethod(resp.delivery)
                    setEntityProvider(resp.entityProvider)
                    let spotifyObj = {
                        spotifyAccess: resp.spotifyAccess,
                        spotifyExpires: resp.spotifyExpires,
                        spotifyUsername: resp.spotifyUsername,
                        // spotifyDeviceId: resp.device_id
                    }

                    setSpotifyAuth(spotifyObj);

                    if (resp.accounts) {
                        let accounts = resp.accounts.map((acc) => {
                            if (acc.subscribedTo) {
                                addSubreddits(acc.subscribedTo);
                                acc.subscribedTo = null;
                            }
                            return acc;
                        });
                        let reddit = accounts.find((acc) => acc.deliveryMethod === DELIVER_BY_REDDIT);
                        let email = accounts.find((acc) => acc.deliveryMethod === DELIVER_BY_EMAIL);
                        setRedditAccount(reddit);
                        setEmailAccount(email);
                    }
                    setIsUserDataLoading(false)
                    setIsUserDataLoaded(true)
                })
                .catch((resp) => {
                    // addToast("Error loading user data.", ERROR_TOAST);
                    logger("Error loading user data.");
                    setIsUserDataLoading(false)
                    setIsUserDataLoaded(true)
                })
        }
    }, [setRedditAccount, setEmailAccount, setIsUserDataLoading, setIsUserDataLoaded, sendRequest,
        shouldLoadData, addToast, addSubreddits, setSpotifyAuth]) //


    const loadSingleConfig = useCallback((subreddit) => {
        setIsConfigDataLoading(true)
        let url = `/api/userconfig/?subreddit=${subreddit}`
        return sendRequest(url, "GET")
            .then((resp) => {
                let newConfig = convertBackendToFrontendJson(resp)[0];
                insertConfigIntoExistingConfigs(newConfig);

                setIsConfigDataLoading(false)
                setIsConfigDataLoaded(true)
                return newConfig;
            })
            .catch((resp) => {
                addToast(`Error loading config data for ${subreddit}`, ERROR_TOAST);
                setIsConfigDataLoading(false)
                setIsConfigDataLoaded(true)
                return resp;
            });

    }, [setIsConfigDataLoading, setIsConfigDataLoaded, addToast, sendRequest, insertConfigIntoExistingConfigs])

    const setHiddenRoundup = useCallback((roundupId, sub) => {
        roundupDataDispatch({type: SET_HIDDEN, payload: {rid: roundupId, sub: sub}})
    }, [roundupDataDispatch])

    const setViewedRoundup = useCallback((roundupId, sub) => {
        roundupDataDispatch({type: SET_VIEWED, payload: {rid: roundupId, sub: sub}})
    }, [roundupDataDispatch])


    const setRoundupMeta = useCallback((data, is_single) => {
        if (is_single) {
            roundupDataDispatch({type: SET_SINGLE_METADATA, payload: data})
        } else {
            if (!data) {
                roundupDataDispatch({type: SET_METADATA, payload: {}})
                return;
            }
            roundupDataDispatch({type: SET_METADATA, payload: data})
        }
    }, [roundupDataDispatch])

    const loadRoundups = useCallback((force) => {
        if (!isRoundupDataLoading && !isRoundupDataLoaded && shouldLoadData(force)) {
            setIsRoundupDataLoading(true)
            sendRequest("/api/roundupsMeta", "GET")
                .then((resp) => {
                    setIsRoundupDataLoaded(true)
                    setIsRoundupDataLoading(false)
                    setRoundupMeta(resp)
                })
                .catch((resp) => {
                    setIsRoundupDataLoading(false)
                    if (shouldShowNavBar()) {
                        addToast("Error loading roundups", ERROR_TOAST)
                        setRoundupMeta(null)
                    }
                })
        }
    }, [addToast, isRoundupDataLoaded, isRoundupDataLoading, sendRequest, setRoundupMeta, shouldLoadData])

    const loadConfigData = useCallback((force) => {
        if (shouldLoadData(force)) {
            setIsConfigDataLoading(true)
            let url = "/api/userconfig/"
            sendRequest(url, "GET")
                .then((resp) => {
                    // logger("/api/userconfig before conversion", resp)
                    let configs = convertBackendToFrontendJson(resp);
                    // logger("/api/userconfig AFTER conversion", configs)
                    configs.sort((a, b) => (a.subreddit.toLowerCase() > b.subreddit.toLowerCase()) ? 1 : -1);
                    setSubConfigs(configs);
                    setIsConfigDataLoading(false)
                    setIsConfigDataLoaded(true)
                    return configs;
                })
                .catch((resp) => {
                    addToast("Error loading config data.", ERROR_TOAST);
                    setIsConfigDataLoading(false)
                    setIsConfigDataLoaded(true)
                    return resp;
                })
        }
    }, [setSubConfigs, setIsConfigDataLoading, setIsConfigDataLoaded, sendRequest,
        shouldLoadData, addToast])

    /**
     * GET /userdata and /userconfig if deemed necessary
     * @type {function(*=): (Promise<* | void>|undefined)}
     */
    const loadAllData = useCallback((force) => {
        if (shouldLoadData(force)) {
            loadUserData(force)
            loadRoundups(force)
            loadConfigData(force)
            setFetchedConfigData(true);
        }
    }, [shouldLoadData, loadUserData, loadRoundups, loadConfigData]);

    const displayToastFromToastCode = useCallback((code) => {
        if (!didShowToast) {
            switch (code) {
                case "emailsuccess":
                    addToast("Successfully verified your email", SUCCESS_TOAST);
                    break;
                case "emailfailure":
                    addToast("There was an error verifying your email. Make sure you click on the most recent " +
                        "verification link that was sent to you.", ERROR_TOAST);
                    break;
                case "redditsuccess":
                    addToast("Successfully connected reddit account.", SUCCESS_TOAST);
                    break;
                case "redditfailure":
                    addToast("There was an error connecting your reddit account.", ERROR_TOAST);
                    break;
                case "redditAlreadyUsed":
                    addToast("This reddit account is already connected to a different Roundup for Reddit account.", ERROR_TOAST);
                    break;
                case "spotifySuccess":
                    addToast("Successfully connected your spotify account", SUCCESS_TOAST);
                    break;
                case "spotifyFailure":
                    addToast("Failed to connect your spotify account", ERROR_TOAST);
                    break;
                default:
                    break;
            }
            setDidShowToast(true);
        }
    }, [setDidShowToast, didShowToast, addToast]);

    const websocketCallback = useCallback((resp) => {
        try {
            let socketObject = JSON.parse(resp.body);
            // check if it's an object (reddit account) or a list (subreddits)
            if (Array.isArray(socketObject)) {
                logger("New list: " + socketObject.length);
                addSubreddits(socketObject);
            } else if (socketObject.new_configs) {
                loadConfigData(true)
            } else {
                setRedditAccount(socketObject)
                if (socketObject.messageError) {
                    addToast(<Button style={{color: "black", backgroundColor: "#F1C40F", border: "none"}}
                                     onClick={() => {
                                         nav("/profile")
                                     }}>
                        I am unable to send a message to your reddit account. Click here for more details
                    </Button>, WARNING_TOAST)
                }
            }
        } catch (e) {
            logger("websocket error:")
            logger(e)
        }
    }, [addSubreddits, loadConfigData, setRedditAccount, addToast, nav])

    useEffect(() => {
        // display a toast if it's in the query params
        let queryParams = parseQuery(window.location.search);
        if (queryParams && queryParams.toast) {
            displayToastFromToastCode(queryParams.toast);
        }

        // register websocket
        if (queryParams && queryParams.websocket && !registeredWebsocket && shouldShowNavBar()) {
            websocket.registerWebsocket(websocketCallback)
            setRegisteredWebsocket(true);
        }

        // save the xsrf (csrf) token
        let xsrfCookie = cookies.get("XSRF-TOKEN");
        if (xsrfCookie && xsrfCookie !== xsrfToken) {
            setXsrfToken(xsrfCookie);
        }

        let navTo = queryParams && queryParams.navTo ? queryParams.navTo.replace(/^\/+/g, "") : null;
        // look for navTo password reset
        if (navTo && (navTo.includes("passwordReset") || navTo.includes("roundups/"))) {
            nav(`/${navTo}`)
            return;
        }

        // check if user is authenticated
        let authCookie = cookies.get("auth")
        let docAuth = document.cookie.includes(" auth=true")
        let goodId = userId && userId !== NULL_USER && userId !== ANON_USER
        let isAuthenticated = goodId || docAuth || authCookie === true || authCookie === "true";
        if (isAuthenticated) {
            // GET /userdata and /userconfig
            loadAllData(false);
            // navigate where the server told us to go
            if (navTo === "prev_location") {
                let prev = sessionStorage.getItem("prev_location")
                if (prev) {
                    sessionStorage.setItem("prev_location", "")
                    nav(prev, {replace: true})
                }
            }
            else if (navTo) {
                nav(`/${navTo}`, {replace: true})
            } else if (window.location.pathname.includes("/welcome")) {
                logger("nav to / - valid auth cookie")
                nav("/", {replace: true})
            }
        } else {
            loadUserData(false)
            setFetchedConfigData(true)
            let prev = sessionStorage.getItem("prev_location")
            if (prev) {
                sessionStorage.setItem("prev_location", "")
                nav(prev, {replace: true})
            } else if(shouldRedirectToWelcome()) {
                nav("/welcome", {replace: true})
                logger("nav to welcome - user cookie2")
            }
        }
    }, [displayToastFromToastCode, loadAllData, nav, setXsrfToken, xsrfToken, registeredWebsocket, websocket,
        websocketCallback, cookies, userId, loadUserData])

    function setRoundupData(data, roundupId) {
        roundupDataDispatch({type: SET_ROUNDUP_POSTS, payload: data, rid: roundupId})
    }
    // noinspection JSValidateTypes
    return (
        <>
        <SubredditConfigContext.Provider value={{
            setConfig: setConfig, deleteConfig: deleteConfig, subreddits: subConfigs,
            isConfigLoaded: isConfigDataLoaded, isConfigLoading: isConfigDataLoading, loadConfigData: loadConfigData,
            isRoundupDataLoaded: isRoundupDataLoaded, isRoundupDataLoading: isRoundupDataLoading,
            loadSingleConfig: loadSingleConfig, roundupData: roundupData, roundupDataDispatch: roundupDataDispatch,
            setRoundupData: setRoundupData, setRoundupMeta: setRoundupMeta, setHiddenRoundup: setHiddenRoundup,
            setViewedRoundup: setViewedRoundup,
        }}>
            <VerifiedAccountsContext.Provider value={{
                displayName: displayName, userId: userId, loggedIn: userId && userId !== ANON_USER && userId !== NULL_USER,
                entityProvider: entityProvider, delivery: deliveryMethod,
                setUserId: setUserId,
                spotifyAuth: spotifyAuth, setSpotifyAuth: setSpotifyAuth, spotifyJsLoaded: spotifyJsLoaded,
                reddit: redditAccount, email: emailAccount,
                isUserLoaded: isUserDataLoaded, isUserLoading: isUserDataLoading, loadUserData: loadUserData,
                setRedditAccount: setRedditAccount, subredditList: subredditList, addSubreddits: addSubreddits
            }}>
                <MyToastContext.Provider value={{addToast: addToast}}>
                    <HttpContext.Provider value={{sendRequest: sendRequest, xsrfToken: xsrfToken}}>
                            <ToastContainer position="top-right" autoClose={7500}
                                            hideProgressBar={false} newestOnTop={false}
                                            closeOnClick rtl={false}
                                            pauseOnFocusLoss draggable pauseOnHover/>

                            {shouldShowNavBar() &&
                                <MyNavBar showError={emailError || redditError}/>
                            }
                            <MediaQueueWrapper>
                                <div className={"routerBody"} id={"routerBodyId"}>
                                    <Router primary={false}>
                                        <WelcomePage path={"/login"}/>
                                        <WelcomePage path={"/signup"}/>
                                        <WelcomePage path={"/welcome/*"}/>
                                        <LazyPasswordReset path={"/passwordReset/:resetCode"}/>
                                        <LazyForgotPassword path={"/forgotPassword"}/>
                                        <LazyEditConfig path={"/editConfig"}/>
                                        <LazyAboutPage path={"/about"}/>
                                        <LazyPrivacyPolicyPage path={"/privacypolicy"}/>
                                        <LazyProfilePage path={"/profile"}
                                                         emailError={emailError}
                                                         redditError={redditError}
                                        />
                                        <ViewRoundup path={"/roundups/:roundupId"}/>
                                        <HomePage path={"/*"}
                                                  subConfigs={subConfigs}/>
                                    </Router>
                                </div>
                            </MediaQueueWrapper>
                    </HttpContext.Provider>
                </MyToastContext.Provider>
            </VerifiedAccountsContext.Provider>
        </SubredditConfigContext.Provider>
        </>
    );
}

export default App;

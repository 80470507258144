import {useCallback, useEffect, useReducer, useState} from "react";
import {cloneDeep, hashCode, logger} from "../Util";

function saveToSessionStorage(key, obj) {
    sessionStorage.setItem(key, JSON.stringify(obj));
}

function getFromSessionStorage(key) {
    try {
        return JSON.parse(sessionStorage.getItem(key));
    } catch (e) {
        return null;
    }
}

export const useReducerWithSessionStorage = (sessionStorageKey, reducer, defaultValue) => {
    let [value, dispatch] = useReducer(reducer, defaultValue, () => {
        let val = getFromSessionStorage(sessionStorageKey)
        if (!val) {
            return defaultValue
        }
        return val
    });

    useEffect(() => {
        saveToSessionStorage(sessionStorageKey, value)
    }, [sessionStorageKey, value]);

    return [value, dispatch];
}


export const useStateWithSessionStorage = (storageKey, defaultVal) => {
    /**
     * Wrapper around the useState hook that tries to retrieve the initial value from session storage
     *  and saves the value in session storage everytime it us updated
     */
    let [value, setValue] = useState(getFromSessionStorage(storageKey) || defaultVal);

    useEffect(() => {
        saveToSessionStorage(storageKey, value)
    }, [storageKey, value]);

    return [value, setValue];
}

const REMOVE_ITEM = "REMOVE_ITEM";
const ADD_ITEM = "ADD_ITEM";
export function listReducer(existingSet, action) {
    let copy = cloneDeep(existingSet);
    if (!copy) {
        copy = new Set()
    }
    if (action) {
        switch (action.type) {
            case REMOVE_ITEM:
                copy.delete(action.data)
                break;
            case ADD_ITEM:
                copy.add(action.data)
                break;
            default:
                break;
        }
    }
    return copy;
}
export const useCachedApi = (apiSite, fetchFunction) => {
    // get the data from sessionstorage or fetch function
    let [loadingList, loadingListDispatch] = useReducer(listReducer, new Set(), () => new Set())

    function setCurrentlyLoading(storageKey) {
        loadingListDispatch({type: ADD_ITEM, data: storageKey});
    }
    function setDoneLoading(storageKey) {
        loadingListDispatch({type: REMOVE_ITEM, data: storageKey});
    }

    return useCallback(async (url, accessToken) => {
        let storageKey = apiSite + "::::" + url + "::::" + hashCode(accessToken)
        let theData = getFromSessionStorage(storageKey)
        if (theData) {
            theData.fromCache = true;
            // logger(`Got data from cache for ${url}`)
        }

        let isLoading = loadingList.has(storageKey)

        let isDataOld = false
        if (theData?.retrievedAt) {
            var millis = Date.now() - theData.retrievedAt;
            var seconds = Math.round(millis / 1000);
            if (seconds > 5) {
                isDataOld = true;
            }
        }
        if ((!theData || isDataOld) && !isLoading) {
            setCurrentlyLoading(storageKey)
            theData = await fetchFunction(url).then((resp) => {
                if (!resp) {
                    logger("Received null/error response ")
                    resp = {error: true}
                }
                // TODO nexxt instead of saving retrievedAt on the object. Save it in the loadingList
                resp.retrievedAt = Date.now();
                saveToSessionStorage(storageKey, resp)
                setDoneLoading(storageKey)
                return resp;
            })
        }
        // return Promise.resolve(theData)
        return theData
    }, [apiSite, fetchFunction, loadingList]);
};

import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import SignupForm from "../auth/SignupForm";
import LoginForm from "../auth/LoginForm";

import {Router, useNavigate} from "@reach/router";
import fb_button from "../../resources/facebook_login_button.png";
import google_button from "../../resources/btn_google_signin_dark_normal_web@2x.png";
import Image from "react-bootstrap/cjs/Image";
import Anchor from "../Anchor";

export default function WelcomePage(props) {
    // let videoRef = useRef(null);
    let nav = useNavigate();
    let msg = window.location.pathname.startsWith("/welcome/signup") ? "Create an account" : "Welcome to Roundup for Reddit"
    return (
        <div className={"spacer centerDiv"}>
            <Card className={"welcomeCard"}>
                <Card.Header as={"h4"}>
                    {msg}
                </Card.Header>
                <Card.Body>

                    {(window.location.pathname === "/welcome" || window.location.pathname === "/welcome/") &&
                        <div>
                            {/*Intro text*/}
                            <Card.Text>
                                This site allows you to create customizable daily or weekly roundups for any subreddit.
                                Stop wasting your time browsing reddit, use RoundupForReddit instead :p
                            </Card.Text>

                            {/*Sign up button*/}
                            <Button onClick={() => nav("/welcome/signup")} variant="primary" style={{marginRight: '15px', marginBottom: "15px"}}>
                                Sign up
                            </Button>
                            {/*Login button*/}
                            <Button onClick={() => nav("/welcome/login")} variant="secondary" style={{marginBottom: "15px"}}>
                                Login
                            </Button>

                            <br/>
                            <br/>
                            Or
                            <br/>
                            {/*Google button*/}
                            <Anchor target={"_self"} href={"/oauth2/authorization/google"}>
                                <Image width={"200px"} src={google_button}/>
                            </Anchor>

                            {/*<br/>*/}
                            {/*<br/>*/}
                            {/*/!*Facebook button*!/*/}
                            {/*<Anchor target={"_self"} href={"/oauth2/authorization/facebook"}>*/}
                            {/*    <Image width={"200px"} src={fb_button} />*/}
                            {/*</Anchor>*/}

                        </div>
                    }

                    <br/>

                    <Router>
                        <LoginForm path={"login"}/>
                        <SignupForm path={"signup"} isPasswordReset={false}/>
                    </Router>
                </Card.Body>
            </Card>
        </div>
    )
}
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {useContext, useState} from "react";
import {ERROR_TOAST, SUCCESS_TOAST} from "../../util/Util";
import {MyToastContext} from "../../util/context/MyToastContext";
import {useNavigate} from "@reach/router";
import {HttpContext} from "../../util/hooks/useHttp";
import {Link} from "@reach/router";
import Anchor from "../Anchor";
import Image from "react-bootstrap/cjs/Image";
import google_button from "../../resources/btn_google_signin_dark_normal_web@2x.png";

export default function SignupForm(props) {
    let httpContext = useContext(HttpContext);
    let nav = useNavigate();
    let toastContext = useContext(MyToastContext);
    let isPasswordReset = props.isPasswordReset;

    let [username, setUsername] = useState("");
    let [password, setPassword] = useState("");
    let [confirmPassword, setConfirmPassword] = useState("");

    function initiateSignup() {
        username = username.trim()
        if (username === "") {
            toastContext.addToast("Please enter a username.", ERROR_TOAST)
        } else if (username.length < 4) {
            toastContext.addToast("Username must be at least 4 characters.", ERROR_TOAST)
        } else if (password.length < 8) {
            toastContext.addToast("Password must be at least 8 characters.", ERROR_TOAST)
        } else if (password.length > 100) {
            toastContext.addToast("Password cannot be more than 100 characters.", ERROR_TOAST)
        } else if (password !== confirmPassword) {
            toastContext.addToast("Your passwords do not match.", ERROR_TOAST)
        } else {
            let postSignupRoundup = sessionStorage.getItem("roundup_signup")
            let body = {"username": username, "password": password}
            if (postSignupRoundup) {
                body["roundupSignup"] = postSignupRoundup
            }
            let opts = {
                method: "POST",
                body: JSON.stringify(body)
            }
            if (isPasswordReset) {
                httpContext.sendRequest(`/passwordReset/${props.resetCode}`, opts).then((resp) => {
                    if (resp && resp.success) {
                        toastContext.addToast("Successfully changed your password.", SUCCESS_TOAST);
                        nav("/welcome")
                    } else {
                        throw new Error();
                    }
                }).catch(() => {
                    toastContext.addToast("There was an error setting your password. The username you" +
                        " entered may be incorrect.", ERROR_TOAST);
                });
            } else {
                httpContext.sendRequest("/signup", opts)
                    .then((resp) => {
                        if (resp && resp.success) {
                            sessionStorage.removeItem("roundup_signup")
                            if (resp.navTo) {
                                nav(resp.navTo)
                            } else {
                                window.location.pathname = "/";
                            }                        }
                    })
                    .catch((resp) => {
                        if (resp && resp.error && resp.error === "username-taken") {
                            toastContext.addToast("That username is already taken.", ERROR_TOAST);
                        } else if (resp && resp.error && resp.error === "username-small") {
                            toastContext.addToast("Username must be at least 4 characters.", ERROR_TOAST);
                        } else if (resp && resp.error && resp.error === "username-large") {
                            toastContext.addToast("Username must be less than 100 characters.", ERROR_TOAST);
                        } else if (resp && resp.error && resp.error === "password-small") {
                            toastContext.addToast("Password must be at least 8 characters.", ERROR_TOAST);
                        } else if (resp && resp.error && resp.error === "username-large") {
                            toastContext.addToast("Password must be less than 100 characters.", ERROR_TOAST);
                        } else {
                            toastContext.addToast("Server error. Please try again later.", ERROR_TOAST);
                        }
                    })
            }
        }
    }

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            initiateSignup();
        }
    }

    return (
        <Form>
            {/*signup username*/}
            <Form.Group controlId="signupFormBasicUsername" className={"centerDiv-fifty"}>
                <Form.Label style={{float: "left"}}>Username:</Form.Label>
                <Form.Control value={username}
                              onKeyPress={handleKeyPress}
                              autoFocus={true}
                              autoComplete={"username"}
                              onChange={(e) => setUsername(e.target.value)}
                              type="text" placeholder="Enter username"/>
            </Form.Group>

            <br/>
            {/*signup password*/}
            <Form.Group controlId="signupFormBasicPassword" className={"centerDiv-fifty"}>
                <Form.Label style={{float: "left"}}>Password:</Form.Label>
                <Form.Control value={password}
                              onKeyPress={handleKeyPress}
                              autoComplete={"new-password"}
                              onChange={(e) => setPassword(e.target.value)}
                              type="password" placeholder="Password"/>
            </Form.Group>
            <br/>

            {/*signup confirm password*/}
            <Form.Group controlId="signupFormConfirmPassword" className={"centerDiv-fifty"}>
                <Form.Label style={{float: "left"}}>Confirm Password:</Form.Label>
                <Form.Control value={confirmPassword}
                              onKeyPress={handleKeyPress}
                              autoComplete={"new-password"}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                              type="password" placeholder="Confirm Password"/>
            </Form.Group>


            <div className={"centerDiv-fifty"}>
                <span style={{float: "left"}}>
                    <Link className={"underline"} to={"/welcome/login"}>
                        Already have an account? Log in here
                    </Link>
                </span>
            </div>
            <br/>
            <br/>
            {/*signup submit*/}
            <Button style={{marginRight: '15px'}} variant="primary"
                    onClick={() => initiateSignup()}>
                Submit
            </Button>
            {" "}
            <Button variant="secondary" onClick={() => {
                nav("/welcome")
            }}>
                Cancel
            </Button>
            <br/>
            <br/>
            Or
            <br/>

            {/*Google button*/}
            <Anchor target={"_self"} href={"/oauth2/authorization/google"}>
                <Image width={"200px"} src={google_button}/>
            </Anchor>

        </Form>
    )
}
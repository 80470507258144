import React from "react";
import AddSubreddit from "../config/AddSubreddit";
import ExistingConfig from "../config/ExistingConfig";
import AllRoundups from "./AllRoundups";

export default function HomePage(props) {
    let subConfigs = props.subConfigs;

    return (
        <div>
            <AddSubreddit subConfigs={subConfigs}/>
            <AllRoundups/>
            <ExistingConfig subConfigs={subConfigs}/>
        </div>
    )
}
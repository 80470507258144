import {createContext, useCallback} from "react";
import {
    getRequestParams,
    logError,
    logResponse,
    readResponseAsJSON,
    readResponseAsTxt,
    validateResponse
} from "../RestUtil";
import {shouldShowNavBar} from "../../App";
import Cookies from 'universal-cookie';
import {useStateWithSessionStorage} from "./useSessionStorage";
import {logger} from "../Util";

export const HttpContext = createContext({sendRequest: null});

export function useHttp() {
    // useContext(HttpContext)
    let [xsrfToken, setXsrfToken] = useStateWithSessionStorage("xsrf-rr", "");

    /**
     * Attempt to fetch a JSON response from the given endpoint
     * @param pathToResource endpoint to query
     * @param options the config object specifying all of the many options
     * @param readBody True if the response is not in JSON format
     * @param options.signal the AbortController signal
     * @param options.body the data to send to the backend
     * @param options.callback function to be called with the JSON response
     * @param options.errorCallback function to be called if an error occurs
     * @param options.method the type of HTTP method to use. Default is GET
     */
    let makeHttpRequestPromise = useCallback((pathToResource, options) => {
        const cookies = new Cookies();
        if (typeof options === "string") {
            options = {method: options}
        }
        if (!options) {
            options = {}
        }
        let readBody = false
        if (options.readBody) {
            delete options.readBody;
            readBody = true;
        }
        const isLocalCall = !pathToResource.includes("https://")
        if (isLocalCall) {
            options["xsrf"] = xsrfToken;
        }
        let {params, opt} = getRequestParams(options, isLocalCall);
        return fetch(pathToResource, params)
            .then(validateResponse)
            .then(readBody ? readResponseAsTxt : readResponseAsJSON)
            .then((resp) => logResponse(resp, options, pathToResource))
            .then(opt.callback)
            .catch((resp) => {
                logError(resp);
                if (resp.status === 401 && shouldShowNavBar() && isLocalCall) {
                    // logger("401 received, redirecting");
                    // If we get an unauthorized error: get rid of the auth cookie, and redirect to login
                    logger("pathname welcome - 401")
                    cookies.set("auth", "false", {maxAge: 0, path: "/"})
                    window.location.pathname = "/welcome"
                }
                // by default this will re-throw the error, so it can be caught by another catch clause
                opt.errorCallback(resp);
            });
    }, [xsrfToken])

    return [xsrfToken, setXsrfToken, makeHttpRequestPromise]
}